import React, { useEffect } from "react";
import styled from "styled-components";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, connectHits, SearchBox } from "react-instantsearch-dom";

import Layout from "@shared/layout";
import { Container, P } from "@util/standard";
import { ProductCard, StandardGridLayout } from "@global";
import { useStore } from "@state/store";
import { SanityProduct } from "@graphql-types";
import { colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { calculateGridItemBorders } from "@util/helper";

const appID = process.env.GATSBY_ALGOLIA_APP_ID as string;
const searchOnlyKey = process.env.GATSBY_ALGOLIA_SEARCH_ONLY_KEY as string;

const algoliaClient = algoliasearch(appID, searchOnlyKey);

export const searchClient = {
  search(requests: any[]) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

const HiddenSearchBox = styled(SearchBox)`
  display: none;
`;

const ResultsContainer = styled(Container)`
  width: 95%;
  margin: 0 auto 40px auto;
  border-bottom: 1px solid ${colorsRGB.navy(0.4)};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    border-bottom: none;
  }
`;

const StyledContainer = styled(Container)<{ hasHits?: boolean }>`
  border-right: 1px solid ${colorsRGB.navy(0.4)};
  width: 30%;
  padding: 0 0 150px 0;
  ${({ hasHits }) => hasHits && `padding: 0; border: none;`};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    margin: auto;
    border-bottom: 1px solid ${colorsRGB.navy(0.4)};
    border-right: none;
    padding-bottom: 40px;
  }
`;

const NoResults = styled.h4`
  margin: 0 auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }
`;

const NoHits = () => {
  return (
    <Container>
      <P>No results</P>
    </Container>
  );
};

const Hits = ({ hits }: any) => {
  const { currentSearchValue } = useStore();

  const regionalisedHits = hits.filter((hit: SanityProduct) => {
    if (hit == null) return null;
    if (hit.regions) {
      return hit.regions.some(
        productRegion => productRegion?.region?.countryCode === process.env.GATSBY_REGION,
      );
    }

    return hit;
  });

  const hasHits = regionalisedHits && regionalisedHits.length > 0;
  const lastItem = regionalisedHits.length;

  return (
    <ResultsContainer>
      <StyledContainer hasHits={hasHits}>
        <Container margin="300px 0 0 22%" tabletMargin="15vh 0 0 0" flexDirection="column">
          <P margin="0">Search results for</P>
          <P margin="5px 0 0 0" fontWeight="bold">
            "{currentSearchValue}"
          </P>
        </Container>
      </StyledContainer>
      <Container
        padding={hasHits ? "254px 0 0 0" : "300px 0 0 0"}
        mobilePadding={hasHits ? "20px 0 0 0" : "40px 0 0 0"}
        margin="0"
        flexDirection="column"
        width="70%"
        tabletWidth="100%"
        tabletMargin="auto"
      >
        {hasHits ? (
          <StandardGridLayout>
            {regionalisedHits.map((product: SanityProduct, index: number) => {
              if (product == null) return null;

              const { noBorderBottom, noBorderLeft } = calculateGridItemBorders(
                index,
                lastItem,
                regionalisedHits.length,
              );

              return (
                <ProductCard
                  data={product}
                  key={product.id}
                  hasBorderLeft={!noBorderLeft}
                  hasBorderBottom={!noBorderBottom}
                />
              );
            })}
          </StandardGridLayout>
        ) : (
          <NoResults>No results for "{currentSearchValue}"</NoResults>
        )}
      </Container>
    </ResultsContainer>
  );
};

const CustomHits = connectHits(Hits);

export default function SearchResults() {
  const { currentSearchValue, categoryFilters } = useStore();

  useEffect(() => {
    if (categoryFilters.length > 0) {
      useStore.setState({ categoryFilters: [] });
    }
  });

  return (
    <>
      <Layout searchBarOpenOnLoad>
        <InstantSearch indexName="Products" searchClient={searchClient}>
          <HiddenSearchBox
            defaultRefinement={currentSearchValue ? currentSearchValue : undefined}
          />
          {currentSearchValue !== null && currentSearchValue.length > 0 ? (
            <CustomHits />
          ) : (
            <NoHits />
          )}
        </InstantSearch>
      </Layout>
    </>
  );
}
